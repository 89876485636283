body {
  background-color: black;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  color: #fffbe1;
}
.subscription {
  padding-top: 2.5rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
a {
  text-decoration: none;
}

.box-input {
  width: 66%;
  max-width: 500px;
  margin: 6rem auto 0;
  text-align: center;
  font-weight: 500;
  position: relative;
  animation: showinRight 0.5s linear;

  .loader {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 36px;
    width: 54px;

    .content-loader {
      width: 54px;
    }
  }

  button {
    position: absolute;
    right: 0;
    bottom: 0px;
    background: #07ffd6;
    display: block;
    height: 36px;
    border-radius: 4px;
    color: white;
    line-height: 36px;
    font-size: 13px;
    width: 54px;
    cursor: pointer;
    text-align: center;
    border: none;

    img {
      width: 16px;
      margin-bottom: 2px;
      vertical-align: middle;
    }
  }

  .input-gral, textarea {
    display: block;
    width: 100%;
    border: none;
    background-color: rgba(255, 255, 255, 0.71);
    border-radius: 4px;
    height: 36px;
    line-height: 36;
    padding: 0 54px 0 14px;
    box-sizing: border-box;
    color: black;
    font-family: "Montserrat", sans-serif;
    margin-top: 6px;
    font-weight: 500;

    &::placeholder {
      color: black;
    }
  }

  &.error {
    color: #ff3333;
    label {
      color: #ff3333;
    }
    .input-gral {
      border: solid 2px #ff3333;
    }
  }
}

.btn-gral {
  display: flex;
  background-color: #07ffd6;
  width: fit-content;
  padding: 12px 19px;
  border-radius: 3px;
  margin: 1.3rem auto 0;
  color: black;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  border: none;
  cursor: pointer;
  img {
    width: 12px;
    margin-left: 10px;
  }
}

.error-sign {
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  background: #e13434;
  padding: 8px 12px;
  width: 46%;
  text-align: center;
  font-size: 13px;
  margin: 0;
  animation: showBounce 0.6s;
}

.social-links {
  color: #9999ff;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  letter-spacing: 3px;
  font-weight: 600;
  animation: showOpacity 0.5s linear;
  text-transform: uppercase;
  width: fit-content;
  animation: showin 0.5s linear;
  border: solid 1px #9999ff;
  padding: 8px 12px;
  border-radius: 5px;
  box-sizing: border-box;
  margin-right: 15px;

  &.ig-link {
    color: #ff0066;
    border: solid 1px #ff0066;
  }
  &.yt-link {
    color: #c4302b;
    border: solid 1px #c4302b;
  }

  img {
    height: 12px;
    margin-right: 7px;
  }

  span {
    margin-left: 12px;
    color: #07ffd6;
  }
}

@keyframes showBounce {
  0%,
  100% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, -40%);
  }
  75% {
    transform: translate(-50%, -10%);
  }
}
@keyframes showinRight {
  from {
    opacity: 0;
    transform: translatex(10%);
  }
}
@keyframes fall-in-slow {
  from {
    transform: translatey(-700%);
  }
}

@keyframes fall-in {
  from {
    transform: translatey(50%);
  }
}

@keyframes showin {
  from {
    opacity: 0;
    transform: translatex(-10%);
  }
}

@keyframes floatWater {
  0% {
    transform: rotateZ(0deg) translateX(0%);
  }
  25% {
    transform: rotateZ(2deg) translateX(-3%);
  }
  50% {
    transform: rotateZ(0deg) translateX(0%);
  }
  75% {
    transform: rotateZ(1deg) translateX(2%);
  }
  100% {
    transform: rotateZ(0deg) translateX(0%);
  }
}

.animationWater {
  animation: move-in-steps 0.5s infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@media (max-width: 1000px) {
  .social-jam {
    text-align: center;
    margin: 1rem auto;
    justify-content: center;
  }
  .error-sign {
    width: fit-content;
  }
  .box-input {
    button {
      height: 46px;
    }

    .input-gral {
      height: 46px;
      font-size: 14px;
      line-height: 46px;
    }
  }
}

@media (max-width: 600px) {
  .social-jam {
    width: 50%;
    margin: 17px auto 0;

    flex-direction: column;
    .social-links {
      margin-right: 0;
      margin-bottom: 15px;
      justify-content: center;
      width: 100%;
    }
  }
}
